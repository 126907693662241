@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
}

html{
  scroll-behavior: smooth;
  animation: ease-in;
}

body {
  margin: 0px !important;
  padding: 0;
  font-family: sans-serif;
  text-align: center;
}
/*set width */

::-webkit-scrollbar{
  width: 3px;
  height: 75px;
}

